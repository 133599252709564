/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:22:04 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 21:07:46
 */
<template>
  <div class="myOrder">
    <div class="personal_top">
      <span
        v-for="(lt, i) in list_top"
        :key="i"
        :class="listTopIndex === lt.id ? 'active' : ''"
        @click="listTopClick(lt)"
        >{{ lt.name }}</span
      >
    </div>
    <div class="cont" v-if="tableData.length > 0">
      <el-row>
        <el-col :span="15">
          <el-row class="cont_top">
            <el-col :span="20">订单详情</el-col>
            <el-col :span="4">单价</el-col>
            <!-- <el-col :span="5">应付金额</el-col> -->
          </el-row>
        </el-col>
        <el-col :span="9">
          <el-row class="cont_top" style="padding: 0">
            <el-col :span="10">应付金额</el-col>
            <el-col :span="7">实付金额</el-col>
            <!-- <el-col :span="6">订单状态</el-col> -->
            <el-col :span="7">操作</el-col>
          </el-row>
        </el-col>
      </el-row>
      <div v-for="(td, t) in tableData" :key="t">
        <el-row>
          <el-col :span="24">
            <el-row class="cont_title">
              <el-col :span="7">交易时间：{{ td.CreateTime }}</el-col>
              <el-col :span="12">订单编号：{{ td.OrderNumber }}</el-col>
              <el-col :span="5" style="text-align: right"
                >状态：
                {{
                  td.Status == 0
                    ? "待支付"
                    : td.Status == 1
                    ? "已完成"
                    : td.Status == 2
                    ? "已关闭"
                    : td.Status == 3
                    ? "分期支付审核中"
                    : td.Status == 4
                    ? "分期中"
                    : "拒绝分期"
                }}</el-col
              >
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="15">
            <el-row
              type="flex"
              align="middle"
              v-for="(tdo, d) in td.OrderDetail"
              :key="d"
              class="cont_details"
            >
              <el-col :span="20" class="cd1">
                <img v-lazy="tdo.CoverImg" alt="" />
                <div>
                  <p>{{ tdo.GoodsName }}</p>
                  <p>讲师： {{ tdo.TeacherNames }}</p>
                </div>
              </el-col>
              <el-col :span="4" class="cd2">
                <p
                  v-if="tdo.DiscountMoney > 0"
                  style="text-decoration: line-through"
                >
                  ￥{{ tdo.OriginalPrice | priceToFixed }}
                </p>
                <p v-else>￥{{ tdo.OriginalPrice | priceToFixed }}</p>
                <p v-if="tdo.DiscountMoney > 0">
                  ￥{{ (tdo.OriginalPrice - tdo.DiscountMoney) | priceToFixed }}
                </p>
                <!-- <p v-if="tdo.DiscountMoney>0">(优惠：￥{{(tdo.OriginalPrice - tdo.DiscountMoney) | priceToFixed}})</p> -->
                <!-- <p v-if="tdo.DiscountMoney>0" style="text-decoration: line-through;">(优惠：￥{{tdo.DiscountMoney | priceToFixed}})</p> -->
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row type="flex" align="middle" class="cont_right">
              <el-col :span="15">
                <p>￥{{ td.TotalMoney | priceToFixed }}</p>
                <!-- <p>优惠：￥{{tdo.DiscountMoney | priceToFixed}}</p> -->
                <p v-if="td.Fee > 0">含手续费：￥{{ td.Fee | priceToFixed }}</p>
                <p v-if="td.InstallmentFee">
                  含分期费：￥{{ td.InstallmentFee | priceToFixed }}
                </p>
              </el-col>
              <el-col :span="9">
                <p>￥{{ td.PayMoney | priceToFixed }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="3" v-if="td.Status != 1">
            <el-row type="flex" align="middle" class="cont_right">
              <el-col :span="24">
                <p v-if="td.Status == 0">
                  <el-button size="mini" type="primary" round @click="pay(td)"
                    >去支付</el-button
                  >
                </p>
                <p
                  v-if="td.Status == 0 || td.Status == 3"
                  style="margin-top: 10px"
                >
                  <el-button size="mini" @click="cancelOrder(td)"
                    >取消订单</el-button
                  >
                </p>
                <p v-if="td.Status == 4">
                  <el-button size="mini" @click="stageDetails(td)"
                    >分期详情</el-button
                  >
                </p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="3" v-else>
            <el-row
              type="flex"
              align="middle"
              v-for="(tdo, d) in td.OrderDetail"
              :key="d"
              class="cont_details"
            >
              <el-col :span="24">
                <el-button
                  v-if="tdo.IsComment == 0"
                  size="mini"
                  @click="evalClick(tdo)"
                  >评价</el-button
                >
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div v-if="tableData.length <= 0">
      <no-data></no-data>
    </div>
    <!-- 微信支付 -->
    <we-chat-payment
      :data="paym"
      :dialogVisible="dialogVisible"
      v-if="dialogVisible"
      @close="getClose()"
    ></we-chat-payment>
    <div v-html="content"></div>
    <!-- 分期详情 -->
    <installment-payment
      :dialogVisible="payDialogVisible"
      :row="payObj"
      v-if="payDialogVisible"
      @close="getClose()"
      @closePay="getClosePay"
    ></installment-payment>
    <!-- 评价 -->
    <evaluate
      :dialogVisible="evaluateDialogVisible"
      :row="evalObj"
      v-if="evaluateDialogVisible"
      @close="getClose()"
    ></evaluate>
  </div>
</template>

<script>
import WeChatPayment from "@/components/WeChatPayment.vue";
import InstallmentPayment from "./components/InstallmentPayment";
import evaluate from "./components/evaluate";
import noData from "@/components/noData";
import { orderQueryOrderList, orderOrderPay, orderCloseOrder, orderQueryPayStatus } from "@/api/order";
export default {
  data () {
    return {
      list_top: [
        { name: "全部", id: "" },
        { name: "待支付", id: 0 },
        { name: "已完成", id: 1 },
        { name: "分期", id: 3 },
      ],
      tableData: [],
      listTopIndex: "",
      page: 1, //页码
      pageSize: 5, //每页条数
      total: 0,
      status: "",
      content: "",
      IsInstallment: "",
      dialogVisible: false,
      paym: {},
      payDialogVisible: false,
      evaluateDialogVisible: false,
      evalObj: {},
      payObj: {},
      timer: "",
    };
  },
  components: {
    WeChatPayment,
    InstallmentPayment,
    evaluate,
    noData
  },
  created () { },
  mounted () {
    this.getOrderQueryOrderList();
  },
  methods: {
    // 评价
    evalClick (obj) {
      this.evalObj = obj;
      this.evaluateDialogVisible = true;
    },
    getClose () {
      this.dialogVisible = false;
      this.payDialogVisible = false;
      this.evaluateDialogVisible = false;
      this.getOrderQueryOrderList();
    },
    getClosePay (obj) {
      this.payDialogVisible = false;
      this.getRse(obj);
    },
    //列表
    async getOrderQueryOrderList () {
      let parm = {
        IsInstallment: this.IsInstallment,
        status: this.status,
        pageIndex: this.page,
        pageSize: this.pageSize,
        category:1
      }
      const res = await orderQueryOrderList(parm);
      if (res.success === true) {
        this.tableData = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 
    stageDetails (td) {
      this.payObj = td;
      this.payDialogVisible = true;
    },
    //取消订单
    async cancelOrder (obj) {
      const res = await orderCloseOrder(obj.Id);
      if (res.success === true) {
        this.$message.success(res.msg);
        this.getOrderQueryOrderList();
      } else {
        this.$message.error(res.msg);
      }
    },
    //待支付
    async pay (obj) {
      let parm = {
        paymentMethod: obj.PaymentMethod,
        orderSource: "PC",
        Id: obj.Id,
      }
      const res = await orderOrderPay(parm);
      this.$set(res, "PaymentMethod", obj.PaymentMethod);
      this.$set(res, "TotalMoney", obj.TotalMoney);
      this.getRse(res);
    },
    getRse (res) {
      if (res.success === true) {
        if (res.PaymentMethod === 0) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.getOrderQueryOrderList();
          }, 500)
        } else if (res.PaymentMethod === 1) {
          this.content = res.response.PayData;
          setTimeout(() => {
            // this.open();
            // 获取到后端返回的form表单name名称,可以自己输出查看
            document.getElementsByName('submit')[0].setAttribute('target', '_blank'); // 新窗口打开
            document.getElementsByName('submit')[0].submit();
          }, 500)
          this.timer = setInterval(() => {
            this.postorderQueryPayStatus(res.response);
          }, 3 * 1000)
        } else if (res.PaymentMethod === 2) {
          setTimeout(() => {
            this.dialogVisible = true;
            this.paym.code = res.response.PayData;
            this.paym.TotalMoney = res.TotalMoney;
          }, 500)
          this.timer = setInterval(() => {
            this.postorderQueryPayStatus(res.response);
          }, 3 * 1000)
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 根据支付id查询支付状态
    async postorderQueryPayStatus (obj) {
      let parm = {
        id: obj.Id,
        isInstallment: obj.IsInstallment,
      }
      const res = await orderQueryPayStatus(parm);
      if (res.response === true) {
        this.dialogVisible = false;
        this.$message.success("支付成功");
        this.getOrderQueryOrderList();
        clearInterval(this.timer);
      }
    },
    open () {
      this.$alert('是否已支付', '提示', {
        confirmButtonText: '确定',
        center: true,
        callback: action => {
          this.getOrderQueryOrderList();
        }
      });
    },
    //分页
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getOrderQueryOrderList();
    },
    listTopClick (obj) {
      this.listTopIndex = obj.id;
      this.page = 1;
      if (this.listTopIndex === 3) {
        this.IsInstallment = 1;
        this.status = "";
      } else {
        this.IsInstallment = "";
        this.status = obj.id;
      }
      this.getOrderQueryOrderList();
    },
  },
  destroyed () {
    clearInterval(this.timer);
  }
};
</script>

<style lang="less" scoped>
.myOrder {
  .cont {
    padding: 30px;
    font-size: 13px;
    .cont_top {
      height: 40px;
      line-height: 40px;
      background: #f3f3f3;
      color: #666666;
      text-align: center;
      // margin-bottom: 20px;
      font-size: 15px;
      padding: 0 20px;
    }
    .cont_title {
      height: 35px;
      line-height: 35px;
      background: #f3f3f3;
      margin-top: 10px;
      font-size: 14px;
      padding: 0 20px;
    }
    .cont_details {
      font-size: 13px;
      padding: 10px 20px;
      text-align: center;
      height: 120px;
      .cd1 {
        display: flex;
        text-align: left;
        img {
          width: 160px;
          height: 100px;
        }
        > div {
          flex: 1;
          margin-left: 20px;
          p {
            &:first-child {
              font-size: 15px;
              font-weight: bold;
            }
            &:last-child {
              color: #666666;
              margin-top: 20px;
            }
          }
        }
      }
      .cd2 {
        p {
          &:last-child {
            margin-top: 10px;
            color: #666666;
          }
        }
      }
      .cd3 {
        p {
          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }
    .cont_right {
      text-align: center;
    }
  }
}
</style>