/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:29:45 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 21:13:13
 */
<template>
  <div class="evaluate">
    <el-dialog
      title="评价"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="evaluate_cont">
        <el-row>
          <el-col :span="3">评价内容：</el-col>
          <el-col :span="21">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入内容"
              v-model="content"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleFinde" type="primary">确 定</el-button>
        <el-button @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addCourseComment } from "@/api/personal";
export default {
  props: ["dialogVisible", "row"],
  data () {
    return {
      results: {},
      content: ""
    };
  },
  created () { },
  mounted () {
    console.log(this.row, '')
  },
  methods: {
    async handleFinde () {
      if (this.content == '') {
        this.$message.error("请输入评价");
        return false
      }
      let user = JSON.parse(this.$store.state.userInfo);
      let pram = {
        courseId: this.row.GoodsId,
        studentId: user.Id,
        studentName: user.RealName,
        remark: this.content
      }
      let res = await addCourseComment(pram);
      if (res.success === true) {
        this.$message.success(res.msg);
        this.$emit("close");
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClose () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0 0;
      background-color: #1288f4;
      // padding: 0;
      .el-dialog__title {
        color: #ffffff;
        // height: 60px;
        // line-height: 60px;
        // padding: 0 30px;
      }
    }
    .evaluate_cont {
      line-height: 40px;
      .el-row {
        margin-bottom: 10px;
        font-size: 14px;
        .comment {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>