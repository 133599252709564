/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:23:42 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-06 15:24:03
 */
<template>
  <div class="InstallmentPayment">
    <el-dialog
      title="查询结果"
      :visible.sync="dialogVisible"
      width="75%"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="InstallmentPayment_cont">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" width="50">
          </el-table-column>
          <el-table-column align="center" label="最后还款时间">
            <template slot-scope="scope">
              {{ scope.row.RepaymentTime | dataYMD }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="应付金额">
            <template slot-scope="scope">
              ￥{{ scope.row.Money | priceToFixed }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="手续费">
            <template slot-scope="scope">
              ￥{{ scope.row.Fee | priceToFixed }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="IsRepayment" label="是否还款">
            <template slot-scope="scope">
              {{ scope.row.IsRepayment == 1 ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="已还款">
            <template slot-scope="scope">
              ￥{{ scope.row.RepaymentMoney | priceToFixed }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="还款方式">
            <template slot-scope="scope">
              <el-radio-group
                v-if="scope.row.IsRepayment != 1"
                v-model="scope.row.mode"
                style="text-align: left"
                @change="handChange(scope.row)"
              >
                <p>
                  <el-radio :label="0">普利点</el-radio>
                </p>
                <p style="margin-top: 5px">
                  <el-radio :label="1">支付宝</el-radio>
                </p>
                <p style="margin-top: 5px">
                  <el-radio :label="2">微信</el-radio>
                </p>
              </el-radio-group>
              <div v-if="scope.row.PaymentMethod != null">
                {{
                  scope.row.PaymentMethod == 0
                    ? "普利点"
                    : scope.row.PaymentMethod == 1
                    ? "支付宝"
                    : "微信"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作">
            <template slot-scope="scope">
              <div v-if="scope.row.IsRepayment != 1">
                <el-button size="mini" type="warning" @click="pay(scope.row)"
                  >去还款</el-button
                >
              </div>
              <div v-else>已还款</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderQueryInstallmentOrderDetail,
  orderInstallmentOrderPay,
} from "@/api/order";
export default {
  props: ["dialogVisible", "row"],
  data() {
    return {
      tableData: [],
      isPay:"",
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    async handChange(row) {
      console.log(row, "");
      this.isPay = 0;
      let res = await this.postorderInstallmentOrderPay(row);
      if (res.success === true) {
        row.Fee = res.response.Fee;
      } else {
        this.$message.error(res.msg);
      }
    },
    //去还款
    async pay(row) {
      // console.log(row.mode)
      if (row.mode === "") {
        this.$message.info("请选择还款方式！");
        return false;
      }
      this.isPay = 1;
      let res = await this.postorderInstallmentOrderPay(row);
      if (res.success === true) {
        this.$set(res, "PaymentMethod", row.mode);
        this.$set(res, "TotalMoney", res.response.TotalMoney);
        this.$emit("closePay", res);
      } else {
        this.$message.error(res.msg);
      }
    },
    async postorderInstallmentOrderPay(row){
      let parm = {
        id: row.Id,
        paymentMethod: row.mode,
        orderSource: "PC",
        isPay:this.isPay //1发起支付，0不发起支付，
      };
      let res = await orderInstallmentOrderPay(parm);
      return res;
    },
    //获取订单分期信息
    async init() {
      let res = await orderQueryInstallmentOrderDetail(this.row.Id);
      if (res.success === true) {
        this.tableData = res.response;
        this.tableData.forEach((item) => {
          this.$set(item, "mode", "");
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.InstallmentPayment {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0 0;
      background-color: #1288f4;
      // padding: 0;
      .el-dialog__title {
        color: #ffffff;
        // height: 60px;
        // line-height: 60px;
        // padding: 0 30px;
      }
    }
    .InstallmentPayment_cont {
      line-height: 40px;
      .el-row {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>